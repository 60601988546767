import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Nav, Navbar, OverlayTrigger, Popover } from "react-bootstrap"
import { useGlobalState } from "../contexts/global_state"
import "./navigation_bar.scss"
import PlanInterface from "../interfaces/plan"
import cartIcon from "../images/cartIcon.png"
const navItems = [
  { name: "Home", slug: "/" },
  { name: "Permit Plans", slug: "/permit-plans" },
]

export default function NavigationBar({ current }: any) {
  const [state, dispatch] = useGlobalState()
  function CartItem({ plan }: { plan: PlanInterface }) {
    return (
      <div className="d-flex align-items-start">
        <div className="m-2">
          <img width={100} src={plan.imageURL} alt={plan.name} />
        </div>
        <div>
          <div className="h6 m-2">{plan.name}</div>
          <div className="m-2 font-weight-light">${plan.price}</div>
          <div className="mx-2">
            <img
              onClick={() =>
                dispatch({ type: "REMOVE_FROM_CART", payload: plan.id })
              }
              className="pointer-on-hover"
              src="https://img.icons8.com/material-rounded/20/000000/delete-forever.png"
            />
          </div>
        </div>
      </div>
    )
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Cart</Popover.Title>
      <Popover.Content>
        <div className="my-2">
          Cart Subtotal: $
          {state.cart.reduce((accu, curr) => accu + curr.price, 0)}
        </div>
        <button
          onClick={() => navigate("/checkout")}
          disabled={state.cart.length === 0}
          className="navigation-button btn btn-block"
        >
          Checkout
        </button>
        <hr />
        {state.cart.length === 0 && (
          <div className="text-center m-3">Cart Empty</div>
        )}
        {state.cart.map(plan => (
          <div key={plan.id} className="m-2">
            <CartItem plan={plan} />
          </div>
        ))}
      </Popover.Content>
    </Popover>
  )

  return (
    <Navbar expand="lg" className="navigation-bar align-items-center">
      <Navbar.Brand className="flex-grow-1">
        <StaticImage
          className="pointer-on-hover"
          onClick={() => {
            navigate("/")
          }}
          placeholder="none"
          height={80}
          alt="logo"
          src="../images/logo.png"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="d-flex align-items-center ml-auto">
          {navItems.map(item => (
            <Nav.Item
              className={"myMargin "+
                (current === item.slug
                  ? "navigation-item-active"
                  : "navigation-item")
              }
              key={item.slug}
            >
              <Link to={item.slug}>
                {item.name}
              </Link>
            </Nav.Item>
          ))}
          <Nav.Item>
            <OverlayTrigger
              trigger="focus"
              placement="bottom-end"
              overlay={popover}
            >
              <button
                style={{ width: 40, height: 40 }}
                className="navigation-button btn m-2"
              >
                <img alt="cart" src={cartIcon} className="m-0 p-0" />
              </button>
            </OverlayTrigger>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
